<template>
	<div
		class=""
	>
		<div class="">
			<h6 class="text-left">영업점</h6>
			<div class="top-line-identify text-left box pa-10 bg-eee font-weight-bold">
				{{ upper_name }}
			</div>
		</div>
		<div class="mt-10">
			<h6 class="text-left">매출 합계</h6>
			<table class="table top-line-identify">
				<thead>
				<tr>
					<th>매출 시작일</th>
					<th>매출 종료일</th>
					<th>정산 기준일</th>
					<th>승인금액</th>
					<th>차감금액</th>
					<th>정산금액</th>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td>{{ summary.fromDate | transDate }}</td>
					<td>{{ summary.toDate | transDate }}</td>
					<td>{{ summary.settlementDate | transDate }}</td>
					<td class="text-right">{{ summary.approvalAmount | makeComma }}원</td>
					<td class="text-right">{{ summary.deductionAmount | makeComma }}원</td>
					<td class="text-right">{{ summary.settlementAmount | makeComma }}원</td>
				</tr>
				</tbody>
			</table>
		</div>

		<div class="mt-10 full-height overflow-y-auto">
			<h6 class="justify-space-between">
				매출 내역

				<div>
					<button
						class="box mr-10 pa-4-10 size-px-12"
						@click="toExcel"
					><v-icon small class="color-green ">mdi mdi-file-excel</v-icon> <span class="vertical-middle">엑셀 다운로드</span></button>
					<select
						class="pa-5-10  size-px-12"
						v-model="search.size"
						@change="getSearch()"
					>
						<option
							v-for="cnt in codes.list_cnt"
							:key="'cnt_' + cnt"
							:value="cnt"
						>{{ cnt }} 건씩 보기</option>
					</select>
				</div>
			</h6>
			<table class="table top-line-identify">
				<colgroup>
					<col width="60px" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />

					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />

					<col width="auto" />
				</colgroup>
				<thead>
				<tr>
					<th>NO</th>
					<th>승인일시</th>
					<th>정산 기준일</th>
					<th>서비스 타입</th>
					<th>가맹점</th>

					<th>승인번호</th>
					<th>할부</th>
					<th>승인금액</th>
					<th>수수료율</th>
					<th>수수료(부가세포함)</th>

					<th>정산금액</th>
				</tr>
				</thead>
				<tbody>
				<tr
					v-for="(item, index) in item_list"
					:key="'item_' + index"
				>
					<td>{{ index+1 }}</td>
					<td>{{ item.approvalDateTime ? item.approvalDateTime.replaceAll('-', '.').replace('T', ' ') : '-' }}</td>
					<td>{{ item.settlementDate | transDate }}</td>
					<td>{{ item.service_name }}</td>
					<td>{{ item.shopName }}</td>

					<td>{{ item.approvalNumber ? item.approvalNumber : '-' }}</td>
					<td>{{ item.installment !== null ? item.installment === 0 ? '일시불' : item.installment + '개월' : '-' }}</td>
					<td class="text-right">{{ item.approvalAmount | makeComma }}원</td>
					<td class="text-right">{{ item.feeRate }}%</td>
					<td class="text-right">{{ item.feeAndTaxAmount  | makeComma }}원</td>

					<td class="text-right">{{ item.settlementAmount  | makeComma }}원</td>
				</tr>
				</tbody>
			</table>

			<pagination
				:options="search"

				class="mt-auto"
				@click="getSearch"
			></pagination>
		</div>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"
		></Excel>
	</div>
</template>

<script>

import Pagination from "@/components/Pagination";
import Excel from "@/components/Excel";

export default {
	name: 'SettlementMerchantDetail'
	,components: {Excel, Pagination }
	,props: ['item', 'user']
	,data: function(){
		return {
			items: []
			,search: {
				page: 1
				,size: 10
                ,settlementIds: []
			}
			,summary: {}
			,supBranch: {}
			,is_excel: false
			,excel_data: {
				name: '가맹점 정산 상세 내역'
				,header: [
					{ key: 0, name: '승인 일시', column: 'approvalDateTime'}
					,{ key: 0, name: '정산 기준일', column: 'settlementDate'}
					,{ key: 0, name: '서비스 타입', column: 'serviceType'}
					,{ key: 0, name: '가맹점', column: 'shopName'}
					,{ key: 0, name: '승인번호', column: 'approvalNumber'}
					,{ key: 0, name: '카드사', column: 'cardName'}
					,{ key: 0, name: '카드번호', column: 'cardNumber'}
					,{ key: 0, name: '할부', column: 'installment'}
					,{ key: 0, name: '승인금액', column: 'approvalAmount'}
					,{ key: 0, name: '수수료율', column: 'feeRate'}
					,{ key: 0, name: '수수료(부가세 포함)', column: 'feeAndTaxAmount'}
					,{ key: 0, name: '정산금액', column: 'settlementAmount'}
				]
				,content: null
			}
		}
	}
	,computed: {
		item_list: function(){
            let self = this
			return this.items.filter(function(item){
                for(let i = 0; i < self.codes.settlement_service_type.length; i++){
                    if(item.serviceType === self.codes.settlement_service_type[i].code){
                        item.service_name = self.codes.settlement_service_type[i].name
                        break
                    }
                }
				return item
			})
		}
		,upper_name: function(){
			let name = this.supBranch.branchName
			if(this.supBranch.distributorName){
				name += ' > ' + this.supBranch.distributorName
			}else if(this.supBranch.agencyName){
				name += ' > ' + this.supBranch.agencyName
			} else if(this.supBranch.resellerName){
				name += ' > ' + this.supBranch.resellerName
			}

			return name
		}
	}
	,methods: {
		getData: async function(){
            const data = new URLSearchParams(this.search).toString()

			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'get'
					,url: 'calculate/shop/detail?' + data
				})

				if(result.success){
                    this.items = result.data.data
                    this.$set(this.search, 'total_count', result.data.totalCount)
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}


                // get statistics
                const result2 = await this.$Axios({
                    method: 'get'
                    ,url: 'calculate/shop/detail/statistics?' + data
                })

                if(result2.success){
                    this.summary = result2.data
                }else{
                    this.$layout.setNotify( { type: 'error', message: result2.message})
                }

                // get branch data
                const result3 = await this.$Axios({
                    method: 'get'
                    ,url: 'calculate/shop/detail/branch?' + data
                })

                if(result3.success){
                    this.supBranch = result3.data
                }else{
                    this.$layout.setNotify( { type: 'error', message: result3.message})
                }

			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,getSearch: function(page){
			if(page){
				this.search.page = page
			}

			this.getData()
		}

        , toExcel: async function () {
            try{
                const data = new URLSearchParams(this.search).toString()
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'get'
                    ,url: 'calculate/shop/detail/excel?' + data
                })

                if(result.success){
                    this.excel_data.content = result.data
                    this.is_excel = true
                }else{
                    this.$layout.setNotify( { type: 'error', message: result.message})
                }
            }catch(e){
                this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
                console.log(e)
            }finally {
                this.$layout.offLoading()
            }
        }
	}
	,created() {
        this.$layout = this.$root.$children[0].$children[0]
        if(this.item) {
            for (let i = 0; i < this.item.settlementData.length; i++) {
                this.search.settlementIds.push(this.item.settlementData[i].settlementIdx)
            }
            this.getData()
        }
	}
}
</script>